<template>
  <div>
    <AppMenu />
    <div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
          <p class="mt-4 leading-7 text-gray-600">Ready to schedule a consultation or have questions about our services? Get in touch with us today and one of our team members will be happy to assist you.</p>
        </div>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
          <div class="rounded-2xl bg-gray-50 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Appointments</h3>
            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
              <div>
                <dt class="sr-only">Email</dt>
                <dd><a class="font-semibold text-blue-600" href="mailto:info@majospa.com">info@majospa.com</a></dd>
              </div>
              <div class="mt-1">
                <dt class="sr-only">Phone number</dt>
                <dd>+1 (555) 905-2345</dd>
              </div>
            </dl>
          </div>
          <div class="rounded-2xl bg-gray-50 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Press</h3>
            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
              <div>
                <dt class="sr-only">Email</dt>
                <dd><a class="font-semibold text-blue-600" href="mailto:press@majospa.com">press@majospa.com</a></dd>
              </div>
              <div class="mt-1">
                <dt class="sr-only">Phone number</dt>
                <dd>+1 (555) 905-3456</dd>
              </div>
            </dl>
          </div>
          <div class="rounded-2xl bg-gray-50 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Join our team</h3>
            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
              <div>
                <dt class="sr-only">Email</dt>
                <dd><a class="font-semibold text-blue-600" href="mailto:careers@majospa.com">careers@majospa.com</a></dd>
              </div>
              <div class="mt-1">
                <dt class="sr-only">Phone number</dt>
                <dd>+1 (555) 905-4567</dd>
              </div>
            </dl>
          </div>
          <div class="rounded-2xl bg-gray-50 p-10">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Say hello</h3>
            <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
              <div>
                <dt class="sr-only">Email</dt>
                <dd><a class="font-semibold text-blue-600" href="mailto:hello@majospa.com">hello@majospa.com</a></dd>
              </div>
              <div class="mt-1">
                <dt class="sr-only">Phone number</dt>
                <dd>+1 (555) 905-5678</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Locations</h2>
          <p class="mt-4 leading-7 text-gray-600">Find the location nearest you and let our team of medical professionals help you achieve your aesthetic goals.</p>
        </div>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
   
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.135869466962!2d-87.64081738432093!3d41.88993497922122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2d29e6f58ca5%3A0xec74cdcc37788e20!2sW%20Hubbard%20St%2C%20Chicago%2C%20IL%2060654!5e0!3m2!1sen!2sus!4v1680464811073!5m2!1sen!2sus" width="800" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


        </div>
      </div>
    </div>
  </div>
</div>

    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";

import FooterSection from "../components/FooterSection.vue";


export default {
  name: "Contacts",
  components: {

    AppMenu,
    FooterSection,

  },
};
</script>
